import * as React from "react"
import Metadata from "../components/metadata"
import Layout from "../components/layout"
import Footer from "../components/footer"

export default function Index() {
  return (
    <Layout>
      <Metadata />
      <Footer />
    </Layout>
  );
}